/*! Handmade with love by http://esmes.fi */

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

var queryParams = (function(a) {
  if (a == "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i)
  {
    var p=a[i].split('=', 2);
    if (p.length == 1)
      b[p[0]] = "";
    else
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
})(window.location.search.substr(1).split('&'));

var App = function(options){
  'use strict';
  var a = this;

  //Default configuration, will be overridden with contents from options attribute
  var defaults = {
    targeted_fps: 60,

    scroll_ontop_treshold: 200, //Treshold for scroll being considered to be on top, in px
    load_more_amount: 8 
  };

  //Create config from defaults and options, then delete defaults for accidental reference
  a.config = $.extend(true, {}, defaults, options);
  defaults = null;

  //Handles resizing of the window. Will be throttled.
  a.handleResize = function(){
    a.windowWidth = $(window).width();
    a.windowHeight = $(window).height();
    a.updateSections();
  };

  //Handles scrolling. Will be throttled.
  a.handleScroll = function(){
    var scroll = $(window).scrollTop();

    /*
    //Detect if page is scrolled more down than the treshold
    if(scroll > a.config.scroll_ontop_treshold){
      if(a.scrolled === false){
        a.scrolled = true;
        a.$body.addClass('page-scrolled-down');
      }
    } else {
      if(a.scrolled === true){
        a.scrolled = false;
        a.$body.removeClass('page-scrolled-down');
      }
    }
    */

    /*
    //Calculate change in scroll position
    var scroll_delta = scroll - a.scroll;

    //Scroll direction is down
    if(scroll_delta > 0){
      if(a.scroll_direction !== 1){
        a.scroll_direction = 1;
        a.$body.removeClass('page-scroll-direction-up').addClass('page-scroll-direction-down');
      }
    }
    //Scroll direction is up
    if(scroll_delta < 0){
      if(a.scroll_direction !== -1){
        a.scroll_direction = -1;
        a.$body.removeClass('page-scroll-direction-down').addClass('page-scroll-direction-up');
      }
    }
    */

    a.scroll = scroll;

  };

  a.init = function(){
    a.$body = $('body');

    $('[role="toggle-menu"]').on('click', function(){
      $('body').toggleClass('menu-open');
    });

    $('#menu .opener').on('click', function(e){
      $('#menu').toggleClass('open');
    });

    /*if(typeof localStorage !== "undefined"){
      var cookie_nag_shown = localStorage.getItem('cookie_nag_shown');
      if(!cookie_nag_shown || typeof cookie_nag_shown == "undefined"){
        log('Cookie nag not shown yet');
        $('#cookie-nag').show();
        $('[role="close-cookie-nag"]').on('click', function(){
          localStorage.setItem('cookie_nag_shown', 'true');
          $('#cookie-nag').hide();
        });
      }
    }*/

  };

  a.init();
};

var app;

$(document).ready(function(){

  var version = detectIE();
  if (version !== false && version < 12) {
    $('html').addClass('ie');
  }

  //Dynamic viewport tags for mobile devices, if needed
  if( $('html').hasClass('tablet') ){
    $('head').append($('<meta name="viewport" content="width=1024, user-scalable=no">'));
  } else if( $('html').hasClass('mobile') ){
    $('head').append($('<meta name="viewport" content="width=384, user-scalable=no">'));
  }

  app = new App();

});

// === Helper functions

//Send custom event to GA if it is loaded
function track(type, track_id){
  if (typeof ga != "undefined") {
    log("Tracking: " + type + " - " + track_id);
    ga('send', 'event', type, track_id);
  } else {
    log("ERROR tracking: " + type + " - " + track_id + ": GA not loaded.");
  }
}

function log(obj) {
  if (typeof debug != "undefined" && debug == true && typeof(console) !== 'undefined' && console.log) {
    console.log(obj);
  }
}

//Returns a function, that, when invoked, will only be triggered at most once
//during a given window of time. Normally, the throttled function will run
//as much as it can, without ever going more than once per `wait` duration;
//but if you'd like to disable the execution on the leading edge, pass
//`{leading: false}`. To disable execution on the trailing edge, ditto.
function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}